.background-skills{
    background: linear-gradient(95deg, rgba(176, 109, 238, 0.30) 15.08%, rgba(79, 99, 217, 0.30) 78.4%);
    filter: blur(100px);
}


  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    width: 100%;
    height: 230px;
    margin: 20px auto;
  }

  .red-filter {
  -webkit-filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(103%) contrast(102%);;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(66deg) brightness(103%) contrast(102%);
  }
