@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;1,300&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: -webkit-linear-gradient(top right, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)); /* For Safari */
background: -moz-linear-gradient(top right, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)); /* For Firefox */
background: -o-linear-gradient(top right, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)); /* For Opera */
background: linear-gradient(to top right, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5)); /* Standard */

}
.font-thai{
  font-family: 'Athiti', sans-serif !important;
  
}
.font-english{
  font-family: 'Tektur', sans-serif !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


